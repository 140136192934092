import React from "react";
import { Header } from "../components/header/header.component";
import { Slider } from "../components/slider/slider.component";
import { FiftyFifty } from "../components/image-text/fifty-fifty.component";
import { Features } from "../components/features/features.component";
import { Gallery } from "../components/gallery/gallery.component";
import { Testimonial } from "../components/testimonial/testimonial.component";
import { Footer } from "../components/footer/footer.component";
import { WappButton } from "../components/whatsapp-button/wapp-button.component";
import { RulerFacts } from "../components/ruler-facts/ruler-facts.component";
import { MiniSlider } from "../components/mini-slider/mini-slider.component";


// images import
import aboutImage from '../assets/img/projects/rodeo/rodeo5.png';
import feature1 from '../assets/img/icons/bulldozer.png';
import feature2 from '../assets/img/icons/tubes.png';
import feature3 from '../assets/img/icons/road.png';
import featureBanner1 from '../assets/img/projects/amatista/amatista5.png';
import featureBanner2 from '../assets/img/projects/microvention/microvention5.png';

import './sites.style.css';



export const Home = () => (
    <>
        <Header />
        <WappButton/>
        <Slider />
        <a id="about"></a>
        <FiftyFifty cts={false} aboutImage={aboutImage}/>
        <a id="service"></a>
        <MiniSlider />
        <RulerFacts />
        <Features feat1={feature1} feat2={feature2} feat3={feature3} ban1={featureBanner1} ban2={featureBanner2} />
        <a id="project"></a>
        <Gallery />
        <Testimonial />
        <a id="contact"></a>
        <Footer />
    </>
);
