import React from "react";

export const Testimonial = () => (
    <>  
        <div className="testimonial-cta-area space__bottom--r120">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 space__bottom__md--40 space__bottom__lm--40">
                    <div className="cta-block cta-block--shadow cta-block--bg bg-img" data-bg="assets/img/backgrounds/cta-bg.webp">
                        <div className="cta-block__inner background-color--default-light-overlay space__inner--ry100">
                            <p className="cta-block__light-text text-center">Más de <span>20</span> años</p>
                            <p className="cta-block__semi-bold-text text-center">Creamos tus proyectos <br/> Solo Llamenos)
                        </p>
                                <p className="cta-block__bold-text text-center">6256-7143</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="section-title text-center  space__bottom--40 mx-auto">
                        <h3 className="section-title__sub">Testimonios</h3>
                        <h2 className="section-title__title">¿Qué dicen nuestros clientes?</h2>
                    </div>
                    <div className="testimonial-slider-wrapper space__inner__bottom__md--30  space__inner__bottom__lm--30">
                        <div className="single-testimonial text-center">
                            <p className="single-testimonial__text space__bottom--40"> <span className="quote-left">"</span>
                            La calidad de su trabajo es insuperable, y no dudaría en recomendarlos para cualquier proyecto de construcción. ¡Un servicio de primera categoría! <span
                                className="quote-right">"</span></p>
                            <div className="single-testimonial__rating space__bottom--10">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <h5 className="single-testimonial__author">Orlando Contreras</h5>
                            <p className="single-testimonial__author-des">Costa Rica</p>
                        </div>
                        {/* <div className="single-testimonial text-center">
                            <p className="single-testimonial__text space__bottom--40"> <span className="quote-left">"</span>
                                Publishing packages and web page editors now use Lorem Ipsum as their default model text,
                                and a search for lorem ipsum will uncover many web sites still in infance <span
                                className="quote-right">"</span></p>
                            <div className="single-testimonial__rating space__bottom--10">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <h5 className="single-testimonial__author">John Doe</h5>
                            <p className="single-testimonial__author-des">CTO, HB Group</p>
                        </div>
                        <div className="single-testimonial text-center">
                            <p className="single-testimonial__text space__bottom--40"> <span className="quote-left">"</span>
                                Publishing packages and web page editors now use Lorem Ipsum as their default model text,
                                and a search for lorem ipsum will uncover many web sites still in infance <span
                                className="quote-right">"</span></p>
                            <div className="single-testimonial__rating space__bottom--10">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <h5 className="single-testimonial__author">Jonathon Doe</h5>
                            <p className="single-testimonial__author-des">CIO, Oct Group</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)