import React from "react";

export const Slider = () => (
    <>
        <div className="hero-slider-area space__bottom--r120">
            <div className="hero-slick-slider-wrapper slick-initialized slick-slider">
                <div className="slick-list draggable">
                    <div className="slick-track">
                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false">
                            <div>
                                <div className="single-hero-slider single-hero-slider--background single-hero-slider--overlay position-relative bg-img" data-bg="assets\img\bulldocer-terreno.jpg" >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="hero-slider-content hero-slider-content--extra-space">
                                                    <h3 className="hero-slider-content__subtitle">Desarrollos Horizontales C J P</h3>
                                                    <h2 className="hero-slider-content__title space__bottom--50">Construimos tus sueños, con calidad y confianza 
                                                    </h2>
                                                    <a href="#contact" className="default-btn default-btn--hero-slider" tabindex="0">Contacto</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)