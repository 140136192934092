import React from "react";

import './ruler-fact.style.css';

export const RulerFacts = () => (
    <>
    <div className="fun-fact-area space__bottom--r120">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="fun-fact-wrapper fun-fact-wrapper-bg bg-img" data-bg="assets/img/backgrounds/funfact-bg.webp">
                        <div className="fun-fact-inner background-color--default-overlay background-repeat--x-bottom space__inner--y30 bg-img" data-bg="assets/img/icons/ruler-black.png">
                            <div className="fun-fact-content-wrapper">
                                <div className="single-fun-fact">
                                    <h3 className="single-fun-fact__number counter">Más de 900,000m<sup>2</sup></h3>
                                    <h4 className="single-fun-fact__text">Trabajados en Proyectos</h4>
                                </div>
                                <div className="single-fun-fact">
                                    <h3 className="single-fun-fact__number counter">Más de 20</h3>
                                    <h4 className="single-fun-fact__text">Clientes</h4>
                                </div>
                                <div className="single-fun-fact">
                                    <h3 className="single-fun-fact__number counter">Más de 20 años</h3>
                                    <h4 className="single-fun-fact__text">Experiencia</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)