import React from "react";

export const FiftyFifty = (props) => (
    <>
      <div className="about-area space__bottom--r120 ">
        <div className="container">
            <div className="row align-items-center row-25">
                <div className="col-md-6">
                    <div className="about-image space__bottom__lm--30">
                        <img width="521" height="498" src={props.aboutImage} className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="about-content">
                        <div className="section-title space__bottom--25">
                            <h3 className="section-title__sub">Desde 1990</h3>
                            <h2 className="section-title__title">En Desarrollos Horizontales CJP</h2>
                        </div>
                        <p className="about-content__text space__bottom--40">Nuestra misión es transformar ideas en realidad, ofreciendo soluciones constructivas innovadoras y sostenibles que superen las expectativas de nuestros clientes. Nos comprometemos a entregar proyectos de alta calidad, en tiempo y forma, generando confianza y valor a cada paso del proceso.</p>

                        { props.cta?
                            <a href="contact.html" className="default-btn">Start now</a>
                            :
                            ""
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)