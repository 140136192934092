import React, {useState, useEffect} from "react";

import './mini-slider.style.css';
import './slick.min.css';

import assetMIniSlider1 from '../../assets/img/service/service-man.webp';
import slider1 from '../../assets/img/service/service-1.webp'

export const MiniSlider = () => {
    // Set up the state for the current index
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const services = [
      {
        name: "Construction",
        imgSrc: slider1,
      },
      {
        name: "Renovation",
        imgSrc: slider1,
      },
      {
        name: "Architecture",
        imgSrc: slider1,
      },
      {
        name: "Concrete Supply",
        imgSrc: slider1,
      },
      {
        name: "Laminate Flooring",
        imgSrc: slider1,
      },
      {
        name: "Project Planning",
        imgSrc: slider1,
      },
    ];
  
    const totalSlides = services.length;

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
        );
      }, 1000); // Change slide every 3 seconds
  
      return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [totalSlides]);
  
    return (
    <>
    <div className="service-area">
        <div className="container">
            <div className="row">
                {/* <div className="col-xl-3 col-lg-5">
                    <div className="service-banner space__bottom__md--40 space__bottom__lm--40">
                        <img width="328" height="497" src={assetMIniSlider1} className="float-none" alt="" />
                    </div>
                </div> */}
                <div className="col-xl-12 col-lg-12 mt-0 mt-lg-12 ">
                    <div className="section-title space__bottom--40 center-title">
                        <h3 className="section-title__sub">Servicios</h3>
                        <h2 className="section-title__title">Servicios Especializados en Construcción y Remodelación</h2>
                    </div>
                    {/* <div className="service-slider-wrapper space__bottom__md--40 space__bottom__lm--40 slick-slider-x-gap-30 slick-slider">
      <div className="slider-wrapper">
        <div
          className="slider"
          style={{ 
            transform: `translateX(-${currentIndex * (100 / totalSlides)}%)`,
            width: `${totalSlides /3 * 100}%`,
          }}
        >
          {services.map((service, index) => (
            <div 
              className="single-service text-center col-xl-3" 
              key={index} 
              style={{ width: `${100 / totalSlides}%` }}
            >
              <div className="single-service__image space__bottom--15">
                <a href="service.html">
                  <img
                    width="270"
                    height="194"
                    src={service.imgSrc}
                    className="img-fluid"
                    alt={service.name}
                  />
                </a>
              </div>
              <h4 className="single-service__content">
                <a href="service.html">{service.name}</a>
              </h4>
            </div>
          ))}
        </div>
      </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    </>
)}