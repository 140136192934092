import React from "react";

import './features.style.css';

export const Features = (props) => (
    <>
        <div className="feature-area space__bottom--r120">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 order-2 order-lg-1">
                    <div className="feature-content-wrapper space__bottom--m35">
                        <div className="single-feature space__bottom--35">
                            <div className="single-feature__icon">
                                <img width="53" height="53" src={props.feat1} className="img-fluid" alt=""/>
                            </div>
                            <div className="single-feature__content">
                                <h4 className="single-feature__title">Movimiento de Tierra</h4>
                                <p className="single-feature__text center-description">Realizamos excavaciones y nivelaciones de terreno para preparar superficies óptimas en proyectos de construcción.</p>
                            </div>
                        </div>
                        <div className="single-feature space__bottom--35">
                            <div className="single-feature__icon">
                                <img width="53" height="53" src={props.feat2} className="img-fluid" alt=""/>
                            </div>
                            <div className="single-feature__content">
                                <h4 className="single-feature__title">Tuberías</h4>
                                <p className="single-feature__text center-description">Instalamos sistemas de tuberías eficientes para redes de agua potable, drenaje y alcantarillado.</p>
                            </div>
                        </div>
                        <div className="single-feature space__bottom--35">
                            <div className="single-feature__icon">
                                <img width="53" height="53" src={props.feat3} className="img-fluid" alt=""/>
                            </div>
                            <div className="single-feature__content">
                                <h4 className="single-feature__title">Pavimentación</h4>
                                <p className="single-feature__text center-description">Ofrecemos servicios de pavimentación de alta calidad para vías, estacionamientos y áreas urbanas.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 space__bottom__md--40 space__bottom__lm--40 order-1 order-lg-2">
                    <div className="feature-content-image">
                        <img width="338" height="367" src={props.ban1} className="img-fluid" alt=""/>
                        <img width="301" height="372" src={props.ban2} className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)