import React, {useState, useEffect} from "react";
import { MenuMobile } from "../menu/menu.component";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import LogoDesarrollo from "../../assets/img/logo.svg";
 

export const Header = () => {

     const [sticky, setSticky] = useState('');

     //Function to handle the scroll event
        const handleScroll = () => {
            const header = document.querySelector('.header-area');
            const offSetScroll = header.offsetTop;
            if (window.pageYOffset > offSetScroll) {
                setSticky('is-sticky');
            }
            else {
                setSticky('');
            }
        }
        //Add the event listener
        window.addEventListener('scroll', handleScroll);

    return(
    <>
        <div className={`header-area header-sticky bg-img space__inner--y40 background-repeat--x d-none d-lg-block ${sticky}`} data-bg="assets/img/icons/ruler.webp">
        <div className="header-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="header-top-info">
                            <span className="header-top-info__text">Telefono: 6256-7143</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="header-top-info text-center">
                            <span className="header-top-info__text">8.00 am - 5.00 pm</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="header-top-info text-end">
                            <a className="header-top-info__link" href="https://www.facebook.com/Desarrolloshorizontalescjp" target="_blank"><span><FontAwesomeIcon icon={faFacebookSquare} /></span></a>  
                            <a className="header-top-info__link" href="https://www.instagram.com/desarrolloshorizontales/" target="_blank"><span><FontAwesomeIcon icon={faInstagram} /></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="menu-bar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12  position-relative">
                        <div className="menu-bar-wrapper background-color--default space__inner--x35">
                            <div className="menu-bar-wrapper-inner">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <div className="brand-logo brand-logo-menu">
                                            <a href="index.html">
                                                <img width="142" height="31" src={LogoDesarrollo} className="img-fluid" alt=""/>
                                                {/* <h1>Desarrollos Horizontales</h1> */}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="navigation-area d-flex justify-content-end align-items-center">
                                            <nav className="main-nav-menu">
                                                <ul className="d-flex justify-content-end">
                                                    <li>
                                                        <a href="#">Inicio</a>
                                                        {/* <ul className="sub-menu">
                                                            <li><a href="index.html">Home One</a></li>
                                                            <li><a href="index-2.html">Home Two</a></li>
                                                        </ul> */}
                                                    </li>
                                                    <li><a href="#about">Nosotros</a></li>
                                                    <li>
                                                        <a href="#service">Servicios</a>
                                                        {/* <ul className="sub-menu">
                                                            <li><a href="service.html">Service Page</a></li>
                                                            <li><a href="service-details.html">Service Details Left Sidebar</a></li>
                                                            <li><a href="service-details-right-sidebar.html">Service Details Right Sidebar</a></li>
                                                            <li>
                                                                <a href="#">Submenu Level One</a>
                                                                <ul className="sub-menu">
                                                                    <li><a href="#">Submenu Level Two</a></li>
                                                                    <li><a href="#">Submenu Level Two</a></li>
                                                                </ul>
                                                            </li>
                                                        </ul> */}
                                                    </li>
                                                    <li>
                                                        <a href="#project">Proyectos</a>
                                                        {/* <ul className="sub-menu">
                                                            <li><a href="project.html">Project Page</a></li>
                                                            <li><a href="project-details.html">Project Details</a></li>
                                                        </ul> */}
                                                    </li>
                                                    {/* <li>
                                                        <a href="blog-left-sidebar.html">Blog</a>
                                                        <ul className="sub-menu">
                                                            <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                                                            <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                                            <li><a href="blog-details-left-sidebar.html">Blog Details Left Sidebar</a></li>
                                                            <li><a href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a></li>
                                                            <li>
                                                                <a href="#">Submenu Level One</a>
                                                                <ul className="sub-menu">
                                                                    <li>
                                                                        <a href="#">Submenu Level Two</a>
                                                                        <ul className="sub-menu">
                                                                            <li><a href="#">Submenu Level Three</a></li>
                                                                            <li><a href="#">Submenu Level Three</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li><a href="#">Submenu Level Two</a></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li> */}
                                                    <li><a href="#contact">Contacto</a></li>
                                                </ul>
                                            </nav>
                                            {/* <div className="nav-search-icon">
                                                <button className="header-search-toggle"><i className="fa fa-search"></i></button>
                                                <div className="header-search-form">
                                                    <form action="#">
                                                        <input type="text" placeholder="Type and hit enter"/>
                                                        <button><i className="fa fa-search"></i></button>
                                                    </form>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <MenuMobile sticky={sticky}/>
    </>
);
}