import React, { useState } from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import './justified-gallery.min.css';
import './gallery.style.css';

import imageData from "./gallery.data";

Modal.setAppElement('#root'); // Configurar para accesibilidad

export const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndexArray, setCurrentIndexArray] = useState(0);
    const [currentIndexModal, setCurrentIndexModal] = useState(0);

    // Función para abrir el modal con la imagen seleccionada
    const openModal = (index) => {
        setCurrentIndexArray(index);
        setCurrentIndexModal(index);
        setIsOpen(true);
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="project-area space__bottom--r120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center space__bottom--40 mx-auto">
                                <h3 className="section-title__sub">Proyectos</h3>
                                <h2 className="section-title__title">
                                Tu Socio Confiable en Proyectos de Construcción
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper space__bottom--m5 justified-gallery" id="project-justify-wrapper">
                    {imageData.map((image, index) => (
                        <div className="single-project-wrapper jg-entry jg-entry-visible" key={index}>
                            <a
                                className="single-project-item"
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openModal(index);
                                }}
                            >
                                <img
                                    width="491"
                                    height="359"
                                    src={image[0].src}
                                    className="img-fluid"
                                    alt={image[0].title}
                                />
                                <span className="single-project-title">{image[0].title}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {/* Modal para mostrar las imágenes en carrusel */}
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Image Carousel"
                className="modal modal-gallery"
                overlayClassName="overlay"
            >
                <button onClick={closeModal} className="close-button">X</button>
                <Carousel
                    selectedItem={currentIndexArray}
                    showThumbs={false}
                    showStatus={false}
                    onChange={(index) => setCurrentIndexArray(index)}
                    infiniteLoop
                >
                    {imageData[currentIndexModal].map((image, index) => (
                        <div key={index}>
                            <img src={image.src} alt={image.title} />
                            <p className="legend">{image.title}</p>
                        </div>
                    ))}
                </Carousel>
            </Modal>
        </>
    );
};

