// Simulación de datos desde un JSON

const imageData = [

    [
        {
            src: require('../../assets/img/projects/rodeo/img1.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for Western Shopping Mall'
        },
        {
            src: require('../../assets/img/projects/rodeo/img2.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for City Flyover'
        },
        {
            src: require('../../assets/img/projects/rodeo/img3.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for Highway Carpeting'
        },
        {
            src: require('../../assets/img/projects/rodeo/img4.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for House Infrastructure Making'
        },
        {
            src: require('../../assets/img/projects/rodeo/img5.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for Wooden House'
        },
        {
            src: require('../../assets/img/projects/rodeo/img6.jpeg'),
            title: 'Proyecto El Rodeo',
            description: 'Description for Road Repairing'
        },
    ],
    [
        {
            src: require('../../assets/img/projects/amatista/img1.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Western Shopping Mall'
        },
        {
            src: require('../../assets/img/projects/amatista/img2.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for City Flyover'
        },
        {
            src: require('../../assets/img/projects/amatista/img3.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Highway Carpeting'
        },
        {
            src: require('../../assets/img/projects/amatista/img4.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for House Infrastructure Making'
        },
        {
            src: require('../../assets/img/projects/amatista/img5.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Wooden House'
        },
        {
            src: require('../../assets/img/projects/amatista/img6.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Road Repairing'
        },
        {
            src: require('../../assets/img/projects/amatista/img7.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Apartment Building'
        },
        {
            src: require('../../assets/img/projects/amatista/img8.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Western Shopping Mall'
        },
        {
            src: require('../../assets/img/projects/amatista/img9.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for City Flyover'
        },
        {
            src: require('../../assets/img/projects/amatista/img10.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for Highway Carpeting'
        },
        {
            src: require('../../assets/img/projects/amatista/img11.jpeg'),
            title: 'Proyecto Amatista',
            description: 'Description for House Infrastructure Making'
        },
    ],
    [
        {
            src: require('../../assets/img/projects/microvention/img1.jpeg'),
            title: 'Adoquinado Microvention',
            description: 'Description for Adoquinado Microvention'
        },
        {
            src: require('../../assets/img/projects/microvention/img2.jpeg'),
            title: 'Adoquinado Microvention',
            description: 'Description for Adoquinado Microvention'
        },
        {
            src: require('../../assets/img/projects/microvention/img3.jpeg'),
            title: 'Adoquinado Microvention',
            description: 'Description for Adoquinado Microvention'
        },
        {
            src: require('../../assets/img/projects/microvention/img4.jpeg'),
            title: 'Adoquinado Microvention',
            description: 'Description for Adoquinado Microvention'
        },
        {
            src: require('../../assets/img/projects/microvention/img5.jpeg'),
            title: 'Adoquinado Microvention',
            description: 'Description for Adoquinado Microvention'
        },
    ],
];

export default imageData;