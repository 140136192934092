import React from "react";

import './footer.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faPhone, faMapLocation, faMailBulk } from '@fortawesome/free-solid-svg-icons'

import LogoDess from "../../assets/img/logo-white.png";
import BackGround from "../../assets/img/backgrounds/footer-bg.png";

export const Footer = () => (
    <>
        <div className="footer-area bg-img space__inner--ry120" data-bg={BackGround}>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="footer-widget">
                        <div className="footer-widget__logo space__bottom--40">
                            <a href="index.html">
                                <img width="142" height="31" src={LogoDess} className="img-fluid" alt=""/>
                            </a>
                        </div>
                        <div className="footer-logo-text">
                            <p className="footer-widget__text space__bottom--20 footer-text-center">
                            Somos una empresa dedicada a brindar soluciones completas de construcción, con un enfoque en calidad, eficiencia y 
                            satisfacción del cliente. Construimos tus proyectos con profesionalismo y confianza.</p>
                        </div>
                        <div className="social-icons">
                        <p><a href="https://www.facebook.com/Desarrolloshorizontalescjp" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} /></a> <a href="https://www.instagram.com/desarrolloshorizontales/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="footer-widget space__top--15 space__top__md--40 space__top__lm--40">
                        <h5 className="footer-widget__title space__bottom--20">Información</h5>
                        <ul className="footer-widget__menu">
                        <p>Construyendo tu Futuro, Ladrillo a Ladrillo</p>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="footer-widget space__top--15 space__top__md--40 space__top__lm--40">
                        <h5 className="footer-widget__title space__bottom--20">Horario de Trabajo</h5>
                        <ul className="footer-widget__menu">
                            <p>Trabajamos de Lunes a Sábados. Contáctanos para más información.</p>
                            <ul>
                                <li><strong>Lun - Vier: </strong> 9am - 5pm</li>
                                <li><strong>Sábados:</strong> 9am - 12md</li>
                                <li><strong>Domingo:</strong> Cerrado</li>

                            </ul>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h5 className="footer-widget__title space__top--15 space__bottom--20 space__top__md--40 space__top__lm--40">
                        Contact us</h5>
                    <div className="footer-contact-wrapper">
                        <div className="single-footer-contact">
                            <div className="single-footer-contact__icon"></div>
                            <div className="single-footer-contact__text"><FontAwesomeIcon icon={faMapLocation} /> San José Costa Rica</div>
                        </div>
                        <div className="single-footer-contact">
                            <div className="single-footer-contact__icon"></div>
                            <div className="single-footer-contact__text"> <FontAwesomeIcon icon={faPhone}/> Teléfono: 6256-7143 <br/> </div>
                        </div>
                        <div className="single-footer-contact">
                            <div className="single-footer-contact__icon"><i className="fa fa-globe"></i></div>
                            <div className="single-footer-contact__text"><FontAwesomeIcon icon={faMailBulk}/>  desarrolloshorizontalescjp@hotmail.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="copyright-area background-color--deep-dark space__inner--y30">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <p className="copyright-text">&copy; Desarrollos horizontales 2024 Made by <a href="#" target="_blank">GNook</a></p>
                </div>
            </div>
        </div>
    </div>
    </>
)