import React, {useState} from "react";

import LogoDesarrollo from "../../assets/img/logo-white.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

export const MenuMobile = (props) => {

    //Show or hide menu when click the button
    const [menuActive, setMenuActive] = useState('');
    
    const handleMenu = (shouldShow) => {

        shouldShow ? setMenuActive('active') : setMenuActive('');
        
    }    

    
    return(<>
        <div className={`mobile-header header-sticky bg-img space__inner--y30 background-repeat--x background-color--dark d-block d-lg-none ${props.sticky}`} data-bg="assets/img/icons/ruler.webp">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-6">
                    <div className="brand-logo">
                        <a href="index.html">
                            <img width="142" height="31" src={LogoDesarrollo} className="img-fluid" alt=""/>
                        </a>
                    </div>
                </div>
                <div className="col-6">
                    <div className="mobile-menu-trigger-wrapper text-end" id="mobile-menu-trigger">
                        <span className="mobile-menu-trigger" onClick={() => handleMenu(true)}></span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className={`offcanvas-mobile-menu ${menuActive}`} id="mobile-menu-overlay">
        <a href="javascript:void(0)" className="offcanvas-menu-close" id="mobile-menu-close-trigger">
            <span className="menu-close" onClick={() => handleMenu(false)}></span>
        </a>
        <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
                <nav className="offcanvas-navigation">
                    <ul>
                        <li>
                            <a href="#">Inicio</a>
                        </li>
                        <li><a href="#about">Nosotros</a></li>
                        <li>
                            <a href="#service">Servicios</a>
                        </li>
                        <li>
                            <a href="#project">Proyectos</a>
                        </li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
                <div className="offcanvas-widget-area">
                    <div className="off-canvas-contact-widget">
                        <div className="header-contact-info">
                            <ul className="header-contact-info__list">
                                <li><i className="fa fa-phone"></i> 8888-8888</li>
                                <li><i className="fa fa-clock-o"></i> 9.00 am - 5.00 pm</li>
                            </ul>
                        </div>
                    </div>
                    <div className="off-canvas-widget-social">
                        <a href="https://www.facebook.com/Desarrolloshorizontalescjp" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} /></a> 
                        <a href="https://www.instagram.com/desarrolloshorizontales/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}