import React from "react";

import './wapp-button.style.css';

export const WappButton = () => (
    <>
    <div className="whatsapp-button">
        <a href="https://wa.me/50662567143?text=Buen%20d%C3%ADa%20necesito%20mas%20informaci%C3%B3n%20sobre%20sus%20servicios" target="_blank" rel="noreferrer">
            <img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" alt="whatsapp"/>
        </a>
    </div>
    </>
)